@mixin section {
	margin-bottom: px-to-em($spacing__lg);

	@include respond-to('small') {
		margin-bottom: px-to-em($spacing__xl);
	}
}

.section {
  @include section;
}
