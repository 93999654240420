$button__font-size: 24px;
$button__font-size--mobile: 18px;
$button__padding: 8px;

button {
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  transition: color 0.4s;
  position: relative;
  border-radius: 0;
}

.button {
  @include font($family: $font-family__hind, $size: $button__font-size--mobile);

  line-height: 1.1;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  text-align: center;
  text-transform: uppercase;
  outline: 0;
  padding: px-to-em($button__padding, $button__font-size--mobile) px-to-em($button__padding, $button__font-size--mobile);
  position: relative;
  transition: color 0.3s,
              background-color 0.3s;

  &__label {
    position: relative;
  }

  &__primary {
    $border-size: 3px;

    color: $color__black;
    padding-bottom: px-to-em($button__padding, $button__font-size--mobile);

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $border-size;
      background-color: $color__brand-gold;

      transition: height 0.3s;
      z-index: 0;
    }

    &:hover {
      &:before {
        height: 100%;
      }
    }
  }

  &__primary--white {
    color: $color__white;

    &:hover {
      color: $color__black;
    }
  }

  &__primary--thin {
    $border-size-thin: 1px;

    font-size: inherit;

    &:before {
      height: $border-size-thin;
    }
  }

  @include respond-to('small') {
    font-size: px-to-em($button__font-size);
    padding: px-to-em($button__padding * 1.5, $button__font-size) px-to-em($button__padding, $button__font-size);

    &__primary {
      padding-bottom: px-to-em($button__padding * 1.15, $button__font-size);
    }

    &__primary--thin {
      font-size: inherit;
      padding-bottom: 0.25em;
    }
  }
}

