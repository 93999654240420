$color__brand-gold: #FFC238;


$color__black: #221D23;

$color__blue: #596A85;
$color__red: #ff0000;
$color__gray: #f0f0f0;
$color__gray-shady-lady: #979797;
$color__gray-black-squeeze: #E0E2DB;

$color__gray-text: #8f908e;

$color__white: #fff;

$color__white--opaque: rgba(255, 255, 255, 0.95);

$color__box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
