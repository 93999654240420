// custom spacing between modules for print
.person-card {
  $font-size__title: 24px;
  $font-size__title--mobile: 20px;
  $font-size__details: 14px;

  @include clearfix;

  background-color: $color__gray;
  margin-bottom: px-to-em($spacing__sm * 2.5);
  padding-bottom: px-to-em($spacing__sm);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: $spacing-base;

  &__figure {
     margin-top: px-to-em($spacing__unit) * -1;
     flex-shrink: 0;
  }

  &__img {
    max-width: px-to-em(100px);
  }

  &__body {
    flex-grow: 1;
    padding: px-to-em($spacing__sm) px-to-em($spacing__sm) 0;

  }

  &__level {
    &--hasoffice{
        &:after {
              content: ',';
        }
    }

  }

  &__details,
  &__contact {
    @include font($size: $font-size__details);

    margin-top: px-to-em($spacing__unit, $font-size__details)
  }

  &__details {
    margin-top: 0.25em;
  }

  &__name {
    @include font($size: $font-size__title--mobile);
    @include links__blue;
  }

  &__phone,
  &__email {
    padding-left: 0.25em;
  }
}
