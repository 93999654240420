@mixin titles-hero() {
  @include font($size: $font-size__hero--mobile, $weight: black);
  line-height: 1.3;

  @include respond-to('small') {
    font-size: px-to-em($font-size__hero);
  }
}

@mixin titles-heading() {
  @include font($size: $font-size__h1--mobile);
  line-height: 1.3;

  @include respond-to('small') {
    font-size: px-to-em($font-size__h1);
  }
}


@mixin titles-subheading() {
  @include font($size: $font-size__h2--mobile);
  line-height: 1.3;

  @include respond-to('small') {
    font-size: px-to-em($font-size__h2);
  }
}

@mixin titles-entity () {
  @include font($size: $font-size__h4--mobile);
  line-height: 1.3;

  @include respond-to('small') {
    font-size: px-to-em($font-size__h4);
  }
}

@mixin titles-callout() {
  @include font($size: $font-size__callout--mobile);
  line-height: 1.3;

  @include respond-to('small') {
    font-size: px-to-em($font-size__callout);
  }
}
