.featured-item {
  margin-bottom: $spacing-base;


	.wrapper {
		@include clearfix;
  }

	&__figure {
		margin: px-to-em($wrapper-padding) * -1 auto 0 px-to-em($wrapper-padding) * -1;
    float: left;
		width: 100%;
		padding-bottom: 80%;
		overflow: hidden;
		position: relative;

		> img {
			width: 100%;
			display: block;
			position: absolute;
		}
  }




  &__support-text {
    @include font($family: $font-family__body, $size: $font-size__h6, $size-base: $font-size__h2--mobile);

    display: block;
    margin-top: 1em;

    ul {
      list-style-type: disc;

      ul {
        list-style-type: circle;
        margin-top: px-to-em(calc($spacing__normalize/2));
      }
    }

    ol {
      list-style-type: decimal;
    }

    li {
      margin-bottom: px-to-em(calc($spacing__normalize/2));
    }
  }

  &__cta {
  	@extend %hide-foo;
  }

  &__tag {
    @include font($font-family__body, $font-size__h6 );

    margin-bottom: px-to-em($spacing__sm);
    text-transform: uppercase;
  }


  $content-height: px-to-em(400px);

  background-color: transparent;

  &__figure {
    float: left;
    width: 45%;
    max-width: px-to-em(510px);
    margin: px-to-em($spacing__md)  px-to-em($spacing__md) 0  px-to-em($wrapper-padding * -1) ;
    padding-bottom: 0;
    overflow: hidden;


    > img {
      position: static;
    }

    &.animate-reveal {
      > img {
        transition: transform 0.5s ease-in;
      }
    }
  }

  &__title {
    font-size: px-to-em($font-size__h2);
    line-height: 1.3;
    display: block;
  }

  &__content {
    clear: none;
    padding: px-to-em($spacing__md);
    min-height: $content-height;
  }
}
