
// Media queries breakpoints
// --------------------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-x-tiny:              375px !default;
$screen-tiny:                481px !default;
// Extra small screen / phone
$screen-xs:                  550px !default;
// Small screen / tablet
$screen-sm:                  768px !default;
// Medium screen / desktop
$screen-md:                  992px !default;
// Large screen / wide desktop
$screen-lg:                  1200px !default;
//additional sizes
$screen-xl:                  1366px !default;
$screen-xxl:                 1920px !default;

$breakpoint__landscape: px-to-em($screen-xs);
$breakpoint__small: px-to-em($screen-sm);
$breakpoint__medium: px-to-em($screen-md);
$breakpoint__large: px-to-em($screen-lg);
$breakpoint__tiny: px-to-em($screen-tiny);

$breakpoints: (
  'landscape':  $breakpoint__landscape, // 480px
  'tiny':  $breakpoint__tiny, //767px
  'small':  $breakpoint__small, //767px
  'medium': $breakpoint__medium, //992px
  'large':  $breakpoint__large //1200px
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
