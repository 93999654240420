// custom spacing between modules for print
$spacing-base: 2em;

$wrapper-padding: 35px;
$wrapper-padding--mobile: 20px;

%hide-foo {
  display: none;
}

//global Structure
.breadcrumbs {
  @extend %hide-foo;
}

.site-header {
  margin-bottom: $spacing-base;

  &__options {
    @extend %hide-foo;
  }
}

.contact-callout,
.page-title__cta {
  @extend %hide-foo;
}

.site-footer {
  &__nav {
    @extend %hide-foo;
  }
  &__vendors {
    @extend %hide-foo;
  }
}

.share-this {
  @extend %hide-foo;
}

.related-people__view-all {
  @extend %hide-foo;
}


// Basic Components

.page-title {
  margin-bottom: $spacing-base;

  &--location {
    margin-bottom: 0;
  }
}

.location-metabar {
  margin-bottom: $spacing-base;
}

.button {
  &__primary--white {
    color: $color__black;
  }
}


.tabbed-area {
  position: relative;

  &__nav,
  &__navigator {
    @extend %hide-foo;
  }
}


// Spacing
.copy-block,
.copy-block__content,
.related-insights,
.related-services,
.related-offerings,
.related-people,
.read-more {
  margin-bottom: $spacing-base;
}
