.read-more {

  &__intro {
    margin-bottom: $spacing-base;
  }

  &__expandable {
    height: auto !important;
  }

  &__button {
    display: none;
  }
}
