//------------------------------------------
//				Variables
//------------------------------------------
$font-size-base: 16px;
$font__line-height: 1.5;

$font-size__hero: 85px !default;
$font-size__page-title: 72px !default;
$font-size__h1: 52px !default;
$font-size__h2: 35px !default;
$font-size__h3: 30px !default;
$font-size__h4: 24px !default;
$font-size__h5: 18px !default;
$font-size__h6: 14px !default;

$font-size__hero--mobile: 36px !default;
$font-size__page-title--mobile: 45px !default;
$font-size__h1--mobile: 36px !default;
$font-size__h2--mobile: 28px !default;
$font-size__h3--mobile: 24px !default;
$font-size__h4--mobile: 20px !default;
// $font-size__h5--mobile: 18px !default;
// $font-size__h6--mobile: 16px !default;

$font-size__callout--mobile: 32px;
$font-size__callout: 42px;

$font-size__insight--mobile: 24px;
$font-size__insight: 30px;

$font-size__copy: 20px;

$font-size__copy-summary--mobile: 20px;
$font-size__copy-summary: 24px;

$font-size__copy-blockquote-mobile: 30px;
$font-size__copy-blockquote: 44px;

//------------------------------------------
//				Helpful Functions for font conversion to EM
//------------------------------------------
@function px-to-em($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return (calc($pixels / $base)) * 1em;
}

//------------------------------------------
//				Font Families and Weights
//				https://fonts.google.com/?query=hind&selection.family=GFS+Didot|Hind:400,600,700
//				Hinda:
//					regular,
//					semibold,
//					bold
//				Didot :
//        href="https://cloud.typography.com/7924396/7716992/css/fonts.css"
//
//
//					Medium, Bold
//------------------------------------------

$font-family__ruda: 'Ruda', sans-serif;
$font-family__hind: 'Hind', sans-serif;

$font-family__titles: 'Didot 16 A', 'Didot 16 B', serif;
$font-family__body: $font-family__hind;

$font-weights: (
  regular: 400,
  medium: 500,
	semibold: 600,
	bold: 700,
	black: 900
);

@mixin font($family: null, $size: null, $weight: null, $size-base: null ) {
  font-family: $family;

	@if $size != null {
		font-size: px-to-em($size, $size-base);
  }

  @if $family == $font-family__body {
		font-weight: map-get($font-weights, regular);
  }

	@if map-has-key($font-weights, $weight) {
		font-weight: map-get($font-weights, $weight);
	}
}
