.titles {
	&__heading {
		@include titles-heading;
	}

	&__subheading {
		@include titles-subheading;

		margin-bottom: px-to-em($spacing__sm, $font-size__h2--mobile);
	}

	@include respond-to('small') {

		&__subheading {
			margin-bottom: px-to-em($spacing__md, $font-size__h2);
		}
	}
}
