// custom spacing between modules for print
$spacing-base: 2em;

.profile-header {
  margin-bottom: calc($spacing-base / 2);
  overflow: hidden;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1em;
  overflow: hidden;

  &__figure {
    width: 15em;
    margin-top: 0;
    max-width: px-to-em(150px);
    flex-shrink: 0;
    margin-right: px-to-em($wrapper-padding--mobile);

    & + .profile-header__content {
      padding: px-to-em($wrapper-padding--mobile);

      .profile-header__primary,
      .profile-header__secondary {
        margin: 0;
      }
    }
  }

  &__snapshot {
    display: block;
    min-width: 100%;
    max-width: 100%;
  }

  &__content {
    flex-grow: 1;
    padding: px-to-em($spacing__md) px-to-em($wrapper-padding--mobile);
  }

  &__primary,
  &__secondary {
    margin: 0 auto;
  }

  &__primary  {
    min-height: inherit;
    display: block;
    padding: 0;
  }

  &__secondary {
    background-color: transparent;
    clear: none;
    text-align: left;
    padding: px-to-em($spacing__unit) 0 0;
  }

  &__name {
    display: inline;
  }

  &__vcard {
    @extend %hide-foo
  }

  &__printer {
    @extend %hide-foo
  }

  &__position {
    display: inline-block;
  }

  &__locations {
    display: inline;
  }

  &__location {
    margin-right: 1em;
  }

  &__position {
    border-right: 1px solid $color__gray-shady-lady;
    padding-right: 0.75em;
    margin-right: 0.5em;
  }
}
