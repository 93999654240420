html {
	font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  font: map-get($font-weights, medium) 100% $font-family__titles;
  line-height: $font__line-height;
  min-width: px-to-em(320px) //DW-45
}

html, body {
  &.lock-page-scroll {
    height: 100%;
    overflow: hidden;
  }
}

main {
 display: block;
}

a {
	color: inherit;
	text-decoration: none;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

li {
	margin-bottom: 0;
}


p {
  margin:0 0 px-to-em($spacing__normalize);

  &:last-child {
  	margin-bottom: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  font-weight: normal;
}

fieldset {
	border: 0 none;
	padding: 0;
	margin: 0;
}

iframe {
	@media only screen and (max-width: $screen-sm) {
		width: 100%;
	}
}

.g-recaptcha {
  margin-bottom: 1em;
}

@include respond-to('small') {

	html, body {
	  &.lock-page-scroll {
	    height: auto;
	    overflow: visible;
	  }
	}
}
