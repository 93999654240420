// custom spacing between modules for print
.rte {
  @include font($font-family__body);

  line-height: 1.5;

  & > * {
    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &--summary {
    @include font($font-family__titles, $font-size__copy-summary--mobile,  'medium');

    line-height: 1.4;
  }

  .intro {
    @include font($font-family__titles, $font-size__copy-summary--mobile,  'medium');

    line-height: 1.4;
  }

  a {
    border-bottom: 2px solid $color__brand-gold;
  }

  h1,h2,h3,h4,h5 {
    font-family: $font-family__titles;

  }

  h2 {
    font-size: px-to-em($font-size__h2--mobile);
    margin-bottom: px-to-em($spacing__sm, $font-size__h2--mobile);
    line-height: 1.3;
  }

  h3 {
    @include font($font-family__body, $font-size__h4--mobile);

    color: $color__black;
    margin-bottom: px-to-em($spacing__sm, $font-size__h4--mobile);
  }

  ul, ol {
    margin-bottom: px-to-em($spacing__normalize);
    padding-left: 1.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
      margin-top: px-to-em(calc($spacing__normalize/2));
    }

  }

  ol {
    list-style-type: decimal;

    ol {
      margin-top: px-to-em(calc($spacing__normalize/2));
    }
  }

  li {
    margin-bottom: px-to-em(calc($spacing__normalize/2));
  }

  blockquote, .blockquote {
    $padding-vertical: $spacing__sm;

    position: relative;
    padding: px-to-em($padding-vertical) 0 px-to-em($padding-vertical * 1.25)  px-to-em($padding-vertical * 1.25);
    margin:  px-to-em($spacing__normalize) 0;

    @include font($font-family__titles, $weight: medium);

    &:after {
      content: '';
      display: block;
      width: 75vw;
      background-color: $color__gray;
      position: absolute;
      top: 0;
      left: 75%;
      height: 99%;
      transform: translateX(-100%);
      z-index: -2;
    }

    cite, .cite {
      margin-bottom: px-to-em($padding-vertical * -1);
    }

    p {
      @include font($size: $font-size__copy-blockquote-mobile);
      line-height: 1.15;
      margin-bottom: 0;
    }

    &:before {
      $font__quote: 75px;
      @include font($size: $font__quote);
      content: "“";
      color: $color__blue;
      position: absolute;
      top: 0;
      left: -2%;
      line-height: 1.2;
      z-index: -1;
    }
  }

  cite, .cite {
    display: block;
    text-align: right;
    color: $color__blue;
    font-style: normal;

    @include font($font-family__body);

    &:before {
      content: "— ";
    }
  }

  img {
    max-width: 100%;
    margin-top: px-to-em($spacing__md);
  }

  //education bar admission styles
  .rte-bar-admission-list {
    list-style: none;
    padding-left: 0;
  }

  .rte-bar-admission {
    margin-bottom: 0;
  }

  .rte-bar-year {
    &:before {
      content: ', '
    }
  }

  .rte-bar-school {
    font-weight: map-get($font-weights, 'semibold');
  }

  .rte-education-school {
    @include font($family: $font-family__body, $weight: 'semibold');
  }

  .rte-education {
    & + .rte-education {
      margin-top: px-to-em($spacing__unit);
    }
  }

  .rte-education-distinction {
    font-size: px-to-em($font-size__h6);
    line-height: 1.2;
    li {
      margin-bottom: px-to-em($spacing__unit);
    }
  }
}
