@charset "UTF-8";
/* Variables */
@font-face {
  font-family: "dickinson";
  src: url("../icons/dickinson.ttf") format("truetype"), url("../icons/dickinson.woff") format("woff"), url("../icons/dickinson.svg?vz66cm#../icons/") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dickinson" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e906";
}

.icon-vcard:before {
  content: "\e903";
}

.icon-search:before {
  content: "\e904";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-facebook-f:before {
  content: "\f09a";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-mail:before {
  content: "\e905";
}

.icon-printer:before {
  content: "\e954";
}

.icon-pdf:before {
  content: "\eadf";
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 500 100% "Didot 16 A", "Didot 16 B", serif;
  line-height: 1.5;
  min-width: 20em;
}

html.lock-page-scroll, body.lock-page-scroll {
  height: 100%;
  overflow: hidden;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin-bottom: 0;
}

p {
  margin: 0 0 1.5em;
}
p:last-child {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  iframe {
    width: 100%;
  }
}

.g-recaptcha {
  margin-bottom: 1em;
}

@media (min-width: 48em) {
  html.lock-page-scroll, body.lock-page-scroll {
    height: auto;
    overflow: visible;
  }
}
button {
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  transition: color 0.4s;
  position: relative;
  border-radius: 0;
}

.button {
  font-family: "Hind", sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.1;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  text-align: center;
  text-transform: uppercase;
  outline: 0;
  padding: 0.4444444444em 0.4444444444em;
  position: relative;
  transition: color 0.3s, background-color 0.3s;
}
.button__label {
  position: relative;
}
.button__primary {
  color: #221D23;
  padding-bottom: 0.4444444444em;
}
.button__primary:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #FFC238;
  transition: height 0.3s;
  z-index: 0;
}
.button__primary:hover:before {
  height: 100%;
}
.button__primary--white {
  color: #fff;
}
.button__primary--white:hover {
  color: #221D23;
}
.button__primary--thin {
  font-size: inherit;
}
.button__primary--thin:before {
  height: 1px;
}
@media (min-width: 48em) {
  .button {
    font-size: 1.5em;
    padding: 0.5em 0.3333333333em;
  }
  .button__primary {
    padding-bottom: 0.3833333333em;
  }
  .button__primary--thin {
    font-size: inherit;
    padding-bottom: 0.25em;
  }
}

.titles__heading {
  font-size: 2.25em;
  line-height: 1.3;
}
@media (min-width: 48em) {
  .titles__heading {
    font-size: 3.25em;
  }
}
.titles__subheading {
  font-size: 1.75em;
  line-height: 1.3;
  margin-bottom: 0.7142857143em;
}
@media (min-width: 48em) {
  .titles__subheading {
    font-size: 2.1875em;
  }
}
@media (min-width: 48em) {
  .titles__subheading {
    margin-bottom: 1.1428571429em;
  }
}

.section {
  margin-bottom: 3.75em;
}
@media (min-width: 48em) {
  .section {
    margin-bottom: 5.9375em;
  }
}

.rte {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
.rte > *:last-child {
  margin-bottom: 0;
}
.rte > *:first-child {
  margin-top: 0;
}
.rte--summary {
  font-family: "Didot 16 A", "Didot 16 B", serif;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.4;
}
.rte .intro {
  font-family: "Didot 16 A", "Didot 16 B", serif;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.4;
}
.rte a {
  border-bottom: 2px solid #FFC238;
}
.rte h1, .rte h2, .rte h3, .rte h4, .rte h5 {
  font-family: "Didot 16 A", "Didot 16 B", serif;
}
.rte h2 {
  font-size: 1.75em;
  margin-bottom: 0.7142857143em;
  line-height: 1.3;
}
.rte h3 {
  font-family: "Hind", sans-serif;
  font-size: 1.25em;
  font-weight: 400;
  color: #221D23;
  margin-bottom: 1em;
}
.rte ul, .rte ol {
  margin-bottom: 1.5em;
  padding-left: 1.1em;
}
.rte ul:last-child, .rte ol:last-child {
  margin-bottom: 0;
}
.rte ul {
  list-style-type: disc;
}
.rte ul ul {
  list-style-type: circle;
  margin-top: 0.75em;
}
.rte ol {
  list-style-type: decimal;
}
.rte ol ol {
  margin-top: 0.75em;
}
.rte li {
  margin-bottom: 0.75em;
}
.rte blockquote, .rte .blockquote {
  position: relative;
  padding: 1.25em 0 1.5625em 1.5625em;
  margin: 1.5em 0;
  font-family: "Didot 16 A", "Didot 16 B", serif;
  font-weight: 500;
}
.rte blockquote:after, .rte .blockquote:after {
  content: "";
  display: block;
  width: 75vw;
  background-color: #f0f0f0;
  position: absolute;
  top: 0;
  left: 75%;
  height: 99%;
  transform: translateX(-100%);
  z-index: -2;
}
.rte blockquote cite, .rte blockquote .cite, .rte .blockquote cite, .rte .blockquote .cite {
  margin-bottom: -1.25em;
}
.rte blockquote p, .rte .blockquote p {
  font-size: 1.875em;
  line-height: 1.15;
  margin-bottom: 0;
}
.rte blockquote:before, .rte .blockquote:before {
  font-size: 4.6875em;
  content: "“";
  color: #596A85;
  position: absolute;
  top: 0;
  left: -2%;
  line-height: 1.2;
  z-index: -1;
}
.rte cite, .rte .cite {
  display: block;
  text-align: right;
  color: #596A85;
  font-style: normal;
  font-family: "Hind", sans-serif;
  font-weight: 400;
}
.rte cite:before, .rte .cite:before {
  content: "— ";
}
.rte img {
  max-width: 100%;
  margin-top: 2.5em;
}
.rte .rte-bar-admission-list {
  list-style: none;
  padding-left: 0;
}
.rte .rte-bar-admission {
  margin-bottom: 0;
}
.rte .rte-bar-year:before {
  content: ", ";
}
.rte .rte-bar-school {
  font-weight: 600;
}
.rte .rte-education-school {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  font-weight: 600;
}
.rte .rte-education + .rte-education {
  margin-top: 0.625em;
}
.rte .rte-education-distinction {
  font-size: 0.875em;
  line-height: 1.2;
}
.rte .rte-education-distinction li {
  margin-bottom: 0.625em;
}

.featured-item__cta, .related-insights__see-all, .profile-header__printer, .profile-header__vcard, .tabbed-area__nav, .tabbed-area__navigator, .related-people__view-all, .share-this, .site-footer__vendors, .site-footer__nav, .contact-callout,
.page-title__cta, .site-header__options, .breadcrumbs {
  display: none;
}

.site-header {
  margin-bottom: 2em;
}
.page-title {
  margin-bottom: 2em;
}
.page-title--location {
  margin-bottom: 0;
}

.location-metabar {
  margin-bottom: 2em;
}

.button__primary--white {
  color: #221D23;
}

.tabbed-area {
  position: relative;
}
.copy-block,
.copy-block__content,
.related-insights,
.related-services,
.related-offerings,
.related-people,
.read-more {
  margin-bottom: 2em;
}

.profile-header {
  margin-bottom: 1em;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1em;
  overflow: hidden;
}
.profile-header__figure {
  width: 15em;
  margin-top: 0;
  max-width: 9.375em;
  flex-shrink: 0;
  margin-right: 1.25em;
}
.profile-header__figure + .profile-header__content {
  padding: 1.25em;
}
.profile-header__figure + .profile-header__content .profile-header__primary,
.profile-header__figure + .profile-header__content .profile-header__secondary {
  margin: 0;
}
.profile-header__snapshot {
  display: block;
  min-width: 100%;
  max-width: 100%;
}
.profile-header__content {
  flex-grow: 1;
  padding: 2.5em 1.25em;
}
.profile-header__primary, .profile-header__secondary {
  margin: 0 auto;
}
.profile-header__primary {
  min-height: inherit;
  display: block;
  padding: 0;
}
.profile-header__secondary {
  background-color: transparent;
  clear: none;
  text-align: left;
  padding: 0.625em 0 0;
}
.profile-header__name {
  display: inline;
}
.profile-header__position {
  display: inline-block;
}
.profile-header__locations {
  display: inline;
}
.profile-header__location {
  margin-right: 1em;
}
.profile-header__position {
  border-right: 1px solid #979797;
  padding-right: 0.75em;
  margin-right: 0.5em;
}

.bio-contactbar {
  padding: 0.5em 0;
  text-align: left;
  margin-bottom: 2em;
}
.bio-contactbar__meta-item {
  font-family: "Hind", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  display: inline-block;
  min-width: auto;
  margin-right: 1em;
}
.bio-contactbar__label:after {
  content: ":";
}

.read-more__intro {
  margin-bottom: 2em;
}
.read-more__expandable {
  height: auto !important;
}
.read-more__button {
  display: none;
}

.listing-insights-grid {
  margin: 0;
}
.listing-insights-grid__category {
  font-family: "Hind", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}
.listing-insights-grid__title {
  font-size: 1.5em;
  line-height: 1.4;
}
.listing-insights-grid__item + .listing-insights-grid__item {
  border-top: 1px solid #979797;
}
.listing-insights-grid__link {
  display: block;
  color: #596A85;
  padding: 1.25em 1.25em 1.25em;
}
.listing-insights-grid__link:hover {
  background-color: #596A85;
  color: #fff;
}

.searchbox-hero {
  display: none;
}

.alpha-filters {
  display: none;
}

.search-helpers__controls {
  display: none;
}

.alpha-filters {
  display: none;
}

.person-card {
  background-color: #f0f0f0;
  margin-bottom: 3.125em;
  padding-bottom: 1.25em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 2em;
}
.person-card:after {
  content: " ";
  display: table;
}
.person-card:after {
  clear: both;
}
.person-card__figure {
  margin-top: -0.625em;
  flex-shrink: 0;
}
.person-card__img {
  max-width: 6.25em;
}
.person-card__body {
  flex-grow: 1;
  padding: 1.25em 1.25em 0;
}
.person-card__level--hasoffice:after {
  content: ",";
}
.person-card__details, .person-card__contact {
  font-size: 0.875em;
  margin-top: 0.7142857143em;
}
.person-card__details {
  margin-top: 0.25em;
}
.person-card__name {
  font-size: 1.25em;
  color: #596A85;
  transition: color 0.25s;
}
.person-card__name:hover {
  color: #221D23;
}
.person-card__phone, .person-card__email {
  padding-left: 0.25em;
}

.featured-item {
  margin-bottom: 2em;
  background-color: transparent;
}
.featured-item .wrapper:after {
  content: " ";
  display: table;
}
.featured-item .wrapper:after {
  clear: both;
}
.featured-item__figure {
  margin: -2.1875em auto 0 -2.1875em;
  float: left;
  width: 100%;
  padding-bottom: 80%;
  overflow: hidden;
  position: relative;
}
.featured-item__figure > img {
  width: 100%;
  display: block;
  position: absolute;
}
.featured-item__support-text {
  font-family: "Hind", sans-serif;
  font-size: 0.5em;
  font-weight: 400;
  display: block;
  margin-top: 1em;
}
.featured-item__support-text ul {
  list-style-type: disc;
}
.featured-item__support-text ul ul {
  list-style-type: circle;
  margin-top: 0.75em;
}
.featured-item__support-text ol {
  list-style-type: decimal;
}
.featured-item__support-text li {
  margin-bottom: 0.75em;
}
.featured-item__tag {
  font-family: "Hind", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  margin-bottom: 1.25em;
  text-transform: uppercase;
}
.featured-item__figure {
  float: left;
  width: 45%;
  max-width: 31.875em;
  margin: 2.5em 2.5em 0 -2.1875em;
  padding-bottom: 0;
  overflow: hidden;
}
.featured-item__figure > img {
  position: static;
}
.featured-item__figure.animate-reveal > img {
  transition: transform 0.5s ease-in;
}
.featured-item__title {
  font-size: 2.1875em;
  line-height: 1.3;
  display: block;
}
.featured-item__content {
  clear: none;
  padding: 2.5em;
  min-height: 25em;
}

.forms__message, .forms .forms__invalid-note, .forms .forms__field--ns {
  display: none;
}
.forms .forms__field {
  margin-bottom: 20px;
}