.listing-insights-grid {
  $tile-padding: px-to-em($wrapper-padding--mobile);
  $listing-border: 1px solid $color__gray-shady-lady;


  margin: 0;

  &__category {
  	@include font($font-family__hind, $font-size__h6 );

  	text-transform: uppercase;
  	display: block;
    margin-bottom: $spacing__unit;
  }

  &__title {
    @include font($size: $font-size__insight--mobile);

    line-height: 1.4;
  }

  &__item {
    & + & {
      border-top: $listing-border;
    }
  }

  &__link {
    display: block;
    color: $color__blue;
    padding: $tile-padding $tile-padding $tile-padding;


    &:hover {
      background-color: $color__blue;
      color: $color__white;
    }
  }
}
