.bio-contactbar {
  padding: 0.5em 0;
  text-align: left;
  margin-bottom: $spacing-base;

  &__meta-item {
    @include font($font-family__body, $font-size__h6);

    display: inline-block;
    min-width: auto;
    margin-right: 1em;
  }


  &__label {
    &:after {
      content: ":";
    }
  }
}
