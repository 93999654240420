/* Variables */
$icomoon-font-path: "../icons/" !default;

$ico-icon-name: "dickinson";

@mixin icon-face-family($font-family, $file-path, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}#{$font-family}.ttf") format("truetype"),
      url("#{$file-path}#{$font-family}.woff") format("woff"),
      url("#{$file-path}#{$font-family}.svg?vz66cm##{$file-path}") format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

//sass mappings
$icons: (
  close: "\e900",
  arrow-left: "\e901",
  arrow-right: "\e902",
  arrow-up: "\e906",
  vcard: "\e903",
  search: "\e904",
  twitter: "\f099",
  facebook: "\f09a",
  facebook-f: "\f09a",
  linkedin: "\f0e1",
  youtube: "\ea9d",
  mail: "\e905",
  printer: "\e954",
  pdf: "\eadf",
);

//group icons
$icon-play-button-path1: "\e907";
$icon-play-button-path2: "\e908";

//Mixin to load icon font
@include icon-face-family($ico-icon-name, $icomoon-font-path);

//attribute selector of class name for icons
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $ico-icon-name !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//Generate Class for each icon based on sass mapping object
@each $name, $icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}
