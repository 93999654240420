//standard
$spacing-base: $font-size-base;

//variation
$spacing__sm: 20px;
$spacing__md: 40px;
$spacing__lg: 60px;
$spacing__xl: 95px;

$spacing__unit: 10px;

$spacing__normalize: $spacing-base * $font__line-height;

$spacing__indentation: 100px;
$spacing__indentation-mobile: 40px;
